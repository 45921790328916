import React from "react";

import { Row, Col, Container } from "react-bootstrap";

import Layout from "../../components/Layout";
import Background from "../../components/Background";
import Statistics from "../../components/Statistics";
import Questions from "../../components/Questions";
import WeAreCTA from "../../components/WeAreCTA";
import Button from "../../components/Button";

import styles from "./index.module.scss";



const ServicesPage = () => {
    return (
    <Layout>
      <Background themeType={"oanda"}>      
        <Container  class="container-fluid " >
            <Row  class="row full-width-row" >
                <Col className={styles.firstSection} xs={12}>
                    <h1>Your Verification Process is Compete </h1>
                    <p>
                    If you’d like to chat about how Shift Connect can support you, click below to get in touch. If you’re curious about our service offerings, keep scrolling to learn more. Welcome to the global community.
                    </p>
                    <Button
									to="/contact"
									className={styles.button}
								>
									Get in Touch
					</Button>
                </Col>
                </Row> 
        </Container>
        <div className={styles.container}>
            <Row class="row full-width-row" >
                <Col md={6} className={styles.leftColumn}/>
                <Col md={6} className={styles.rightColumn}>
                   <h2 >Global Money Transfers </h2>
                   <p>Our global payments platform allows you to send money quickly and securely in just a few quick clicks. You can view currency conversions in real-time, send and receive payments, and take advantage of our competitive exchange rates every step of the way. Our platform enables you to send money from the comfort of your own home or office without worrying about hidden fees, extended wait times,  or misinformation. 
                  </p>
                  <h2 >International Payment Processing</h2>
                   <p>With our connective platform, you gain immediate access to the global marketplace. Our leading technology gives you the freedom to process payments, make FX transfers, and book trades – securely, on your own, and in real-time. We are passionate about breaking down the borders surrounding international payments so you can confidently send money abroad without worrying about complex logistics or added costs.              
                   </p>
                </Col>
            </Row> 
            <Row class="row full-width-row" >
                <Col md={6} className={styles.rightColumn}>
                <h2 >Hedging and Treasury </h2>
                   <p>When you partner with Shift Connect, we work with you to build a custom hedging strategy that caters specifically to your financial needs and goals. Our hedging and treasury services offer a holistic approach to analyzing and mitigating your exposure to risk. Beyond that, we seek out opportunities for your business to capitalize on market fluctuations rather than incur loss in these circumstances. Our solutions vary based upon your business's unique complexities. 
                  </p>
                  <h2 >Risk Management</h2>
                   <p>Once we've identified your exposure to foreign exchange risk, we work with you to build out a multi-faceted risk management strategy. We consistently analyze and elevate your unique strategy, working with you to understand and meet your goals in the FX space. When you partner with Shift, you know your funds are protected and your exposure is limited so you can focus on your business's growth. 
                  </p>
                </Col>
                <Col md={6} className={styles.leftColumn2}/>
            </Row> 
            <Row class="row full-width-row" >
                <Col md={6} className={styles.leftColumn3} />
                <Col md={6} className={styles.rightColumn}>
                <h2 >Multi-Currency Accounts </h2>
                   <p>Our corporate and private clients can utilize our secure multi-currency accounts to hold and transact in multiple currencies, all in one place. This service allows you to save and pay in several different currencies without the added stress of opening multiple international bank accounts. While simplifying your payment procedures, it also streamlines your record keeping and accounting procedures in an efficient and centralized manner. 
                  </p>
                  <h2 >Forex Management</h2>
                   <p>If you are seeking a more hands-off approach to your foreign exchange management, we will happily step in as an extension of your finance department. We have a finger on the pulse of the ever-changing market and we will extend our expertise to support you or your business. Our foreign exchange management services are based upon your goals and each strategy we develop is aimed at supporting your unique needs. 
                  </p>
                </Col>
            </Row> 
            <Row class="row full-width-row" >
                <Col md={6} className={styles.rightColumn}>
                <h2 >Custom Business Solutions </h2>
                   <p>Every business is different. Our team strives to gain a top-to-bottom understanding of your foreign exchange and global payments needs to build a service package that is in line with your processes, challenges, and motives. As your business grows and changes, we are committed to agility to continually enhance and build upon your custom solutions. We draw upon our industry knowledge and leading service offerings to position your business for success. 
                  </p>
                  <h2 >Customer Service</h2>
                   <p>Above all else, we are a team of experts who are passionate about serving and supporting you as you enter the foreign exchange and global payments market. Our customer service relationship does not end once you are onboarded. Rather, we are always available to assist you with any questions or ideas you may have every step of the way. From adjusting your service packages to helping you navigate a new type of transaction, we are here for you. Our passionate team is your dedicated resource. 
                  </p>
                </Col>
                <Col md={6} className={styles.leftColumn4}/>
            </Row> 
        </div>    
       <WeAreCTA/>     
        <Statistics themeType={"dark"} />
        <Questions />
      </Background>
    </Layout>
  );
};

export default (ServicesPage);
